@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

.ProductGallery {
    flex-direction: row;
    margin-bottom: 44px;

    @include desktop {
        margin-bottom: 16px;
    }

    &-Slider {
        margin: 0 auto 40px;
    }

    &-Additional {
        order: 0;

        @include desktop {
            margin-right: 15px;
        }
        .CarouselScroll {
            .CarouselScroll-Content {
                border: none;
            }
        }
    }

    &-SliderWrapper {
        order: 1;
    }

    .ProductLabel {
        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 40px;
        }

        &:nth-child(3) {
            top: 80px;
        }

        &:nth-child(4) {
            top: 120px;
        }
    }
}
