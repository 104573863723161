@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --qty-background: #{$purple5};
    --qty-button-border-radius: 50%;
    --qty-button-color: #{$white};
    --qty-button-background: #{$default-primary-base-color};
    --qty-button-background-disabled: #{$purple20};
    --qty-button-border-disabled: #{$purple20};
    --qty-button-border-color: #{$default-primary-base-color};
    --qty-input-background: #{$purple5};
    --qty-input-color: #{$default-primary-base-color};
}

[dir='ltr'] .ProductActions {
    --qty-buttons-padding: 12px;

    &-Title {
        color: $purple80;
    }

    &-AskForProduct {
        a {
            color: $purple80;
            line-height: 1.3;
        }

        svg {
            fill: $orange50;
            stroke: $orange50;
        }
    }

    &-Qty.Field {
        --qty-button-height: 32px;
        --qty-button-width: 32px;
        --qty-background: transparent;
        @include qty-input;

        @include mobile {
            margin-right: 12px;
        }
        @include tablet {
            margin-right: 24px;
        }
        @include desktop {
            margin-right: 24px;
        }
    }

    &-AddToCartWrapper .ProductActions-AddToCart {
        --button-height: 56px;
        --button-hover-height: 56px;
    }

    &-AddToCartMobile .ProductActions-AddToCart {
        margin-right: 0;
        min-width: 135px;
        padding: 0 10px;
        svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
        span {
            font-size: 12px;
        }
    }

    &-DeliveryCost,
    &-DeliveryTime {
        color: $purple80;
    }

    &-DeliveryCost {
        button {
            color: $purple80;
        }
    }

    .ProductWishlistButton,
    .ProductCompareButton {
        svg path {
            stroke: $default-primary-base-color;
        }
        button:hover {
            background: $default-primary-base-color;
        }
    }

    &-AddToCartWrapper .ProductAlerts-InStock,
    &-AddToCartMobile .ProductAlerts-InStock {
        background: $purple50;
        border-color: $purple50;
        border-radius: 100px;
    }
}
