@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --daily-promo-title-background: #{$red-light2};
    --daily-promo-content-background: #{$default-primary-base-color};
    --daily-promo-price-old-color: rgba(255, 255, 255, 0.5);
    --daily-promo-counter-tile-background: #{$default-secondary-base-color};
    --daily-promo-counter-text-color: #{$white};
    --daily-promo-cta-color: #{$default-primary-base-color};
    --daily-promo-cta-hover-background: #{$default-secondary-base-color};
    --daily-promo-cta-hover-border: #{$default-secondary-base-color};
}
.DailyPromoProduct {
    &-Title {
        border-radius: 15px 15px 0 0;
        max-width: 200px;

        @include mobile {
            margin-left: 55px;
        }

        @include from-tablet {
            margin-left: 200px;
            max-width: 200px;
        }

        @include wider-desktop {
            margin-right: 46px;
            margin-left: auto;
        }
    }

    &-Content {
        border-radius: 100px 25px;
        box-shadow: 0px 20px 35px rgba(107, 69, 188, 0.3);
        @include from-tablet {
            min-width: var(--daily-promo-max-width);
            max-width: var(--daily-promo-max-width);
        }

        @include wider-desktop {
            min-width: var(--daily-promo-max-width);
            max-width: var(--daily-promo-max-width);
        }
    }

    &-ImgWrapper {
        &,
        img {
            border-radius: 75px 20px;
        }
    }

    &-AddToCart {
        min-width: 180px;

        @include mobile {
            max-width: 220px;
        }
    }
}
