@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --product-compare-table-text-color: #{$grey3};
    --product-compare-table-title-color: #{$default-primary-base-color};
    --product-compare-high-price-color: #{$grey-dark};
}

[dir='ltr'] .ProductCompareAttributeRow {
    border-top: 1px solid $grey5;

    @include mobileAndTablet {
        display: block;
        margin: 10px 0;
    }

    &-Title {
        color: var(--product-compare-table-title-color);
        font-weight: 600;
        font-size: 15px;
        letter-spacing: 0.05em;

        @include mobileAndTablet {
            padding-inline: 15px;
            padding-block-end: 12px;
            white-space: nowrap;
        }
    }

    &-Values {
        .ProductPrice {
            &-PriceValue {
                font-size: 20px;
                font-weight: 700;
            }

            &-HighPrice {
                color: var(--product-compare-high-price-color);
                font-size: 13px;
            }
        }
    }

    &-Value {
        &,
        p,
        li,
        span {
            font-size: 13px;
            color: var(--product-compare-table-text-color);
            font-weight: 400;
        }

        &:last-child {
            @include mobile {
                padding-right: var(--product-compare-item-gap);
            }
        }
    }
}
