@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

[dir='ltr'] .ProductReviews {
    &-Summary {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
    }
    &-SummaryRating {
        margin: 0;
        svg {
            width: 34px;
            height: 33px;
            left: -7px;
        }
    }
    &-Button {
        min-width: 171px;
        max-width: 171px;
    }
    &-SummaryDetails {
        margin: 0 30px 0 0;
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: $black;
    }
    &-LoadMoreButton {
        display: block;
        min-width: 235px;
        max-width: 235px;
        margin: 40px auto 0;
        font-size: 15px;
        font-family: $font-primary;
        line-height: 140%;
        letter-spacing: 0.07em;

        @include mobile {
            --button-height: 47px;
            --button-hover-height: 47px;
            --button-border-radius: 7px;
            --button-hover-border-radius: 7px;

            min-width: 173px;
            max-width: 173px;
        }

        @include from-tablet {
            margin: 56px auto 0;
        }

        @include desktop {
            margin: 60px 0 0;
        }
    }
}
