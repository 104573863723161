@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --product-label-font: #{$font-primary};
    --product-label-color: #{$white};
    --product-label-background-default: #{$green50};
    --product-label-background-sale: #{$red50};
    --product-label-radius: 5px;
}

.ProductLabel {
    display: inline-block;
    position: absolute;
    top: 20px;
    z-index: 2;
    cursor: pointer;

    &_variant {
        &_Text {
            color: var(--product-label-color);
            letter-spacing: -0.4px;
            font-family: var(--product-label-font);
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            padding: 5px 10px;
            text-transform: capitalize;
            border-radius: 10px;

            &:hover {
                color: var(--product-label-color);
            }
        }

        &_Image {
            img {
                max-height: 35px;
                width: auto;
            }
        }
    }

    &:nth-child(3) {
        top: 60px;
    }

    &:nth-child(4) {
        top: 100px;
    }

    &_side_left {
        left: 0;
    }

    &_side_right {
        right: 0;
    }

    &_radius_left {
        border-radius: 10px 0 0 10px;
    }

    &_radius_right {
        border-radius: 0 10px 10px 0;
    }

    &_type_default {
        background-color: var(--product-label-background-default);
    }

    &_type_sale {
        background-color: var(--product-label-background-sale);
    }
}