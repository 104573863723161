@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --price-color: #{$black};
    --special-price-color: #{$red50};
}

.ProductPrice {
    min-height: unset;
    &-PriceValue {
        font-size: 16px;
        font-family: $font-secondary;
        color: var(--price-color);
        line-height: 162%;
        letter-spacing: -0.2px;
        margin: 0;
        font-weight: bold;
    }
    &_hasDiscount {
        display: flex;
        align-items: baseline;
        .ProductPrice-Price {
            .ProductPrice-PriceValue {
                color: var(--special-price-color);
                margin-right: 12px;
                font-weight: bold;
            }
        }
        .ProductPrice-HighPrice {
            color: var(--price-color);
            opacity: 1;
            font-size: 16px;
            font-weight: normal;
        }
    }
}
