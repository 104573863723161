@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2c/src/style/abstract/variables';
@import '../../../../advox-strigo-b2c/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2c/src/style/abstract/button';

:root {
    --product-compare-close-icon-color: #{$default-primary-base-color};
}

[dir='ltr'] .ProductCompareItem {
    padding-bottom: 24px;

    &-Details {
        margin-bottom: 24px;
    }

    &-AddToCartBtnWrapper,
    &-AddToCartBtn {
        width: 248px;

        @include mobile {
            width: calc(100% - 36px);
        }
    }

    &-Figure {
        width: 105px;
        height: 105px;
        margin-right: 15px;
    }

    &-Title {
        font-size: 13px;
    }

    .CloseIcon {
        fill: var(--product-compare-close-icon-color);
    }

    .ProductReviewRating {
        margin-top: 0;
        margin-bottom: 15px;
        margin-left: -5px;
    }

    .ProductWishlistButton {
        width: 36px;
        height: 36px;
        margin-right: 0;

        @include mobile {
            margin-left: 0;
        }

        &-Button.Button,
        &-Button.Button:hover,
        &-Button.Button:focus {
            width: 36px;
            height: 36px;
            border-radius: 50%;
        }

        svg {
            width: 19px;
            height: 16px;
        }
    }
}
